import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Module } from '@app/models';
import { ActiveModulesStoreSelectors } from '@app/root-store/active-modules-store';
import { UserMePermissionsStoreSelectors } from '@app/root-store/user-me-permissions-store';
import { SelectProperty } from '@core/+state/core.actions';
import { ICoreState } from '@core/+state/core.reducer';
import { select, Store } from '@ngrx/store';
import { get, intersection, intersectionBy, isArray } from 'lodash';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { combineLatest } from 'rxjs';
import { combineLatest as combineLatestOperator } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { propertiesIsEqual } from '../../core/helpers';
import { findUserRole } from '../../helpers';
import { UserMeStoreSelectors } from '../../root-store/user-me-store';

import {
  selectAllProperties,
  selectSelectedProperty,
} from './../../core/+state/core.reducer';
import { IProperty } from './../../features/commons/properties/models/property.model';
import { PropertiesSelectService } from './services/properties-select.service';

export function clearLocalStorageProperties() {
  localStorage.removeItem('allPropertiesIds');
}

export const PARTNER_API_STORAGE_KEY = 'partner_api_selected_properties';

@Component({
  selector: 'by-properties-select',
  templateUrl: './properties-select.component.html',
  styleUrls: ['./properties-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PropertiesSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type: 'multiple' | 'default' = 'default';

  @Input() remember = true;

  @Input() isDisabled = false;

  @Input() onlyView = false;

  @Input() width: string;

  @Input() minWidth = '230px';

  @Input('featurePermission') set _featurePermission(featurePermissionData: {
    permission: string;
  }) {
    if (!featurePermissionData || !featurePermissionData.permission) {
      return;
    }
    const { permission: featurePermission } = featurePermissionData;
    if (
      this.featurePermission === featurePermission &&
      this.allPropertiesIds.length
    ) {
      this.type === 'multiple'
        ? this.selectService.selectedProperties.next(this.allPropertiesIds)
        : this.selectService.selectedProperties.next([this.selectedProperty]);
      this.type === 'multiple'
        ? this.propertiesSelected.emit(this.allPropertiesIds)
        : this.propertiesSelected.emit(this.selectedProperty);
      return;
    }
    this.featurePermission = featurePermission;
    this.onSelectProperties();
  }

  @Input('requiredModule') set _requiredModule(requiredModule: Module) {
    if (this.requiredModule === requiredModule) {
      return;
    }
    this.requiredModule = requiredModule;
    this.onSelectProperties();
  }

  @Input() showWithSingleProperty = false;

  @Input() skipLocalStorageProperties = true;

  @Output() propertiesSelected = new EventEmitter<number | number[]>();

  properties: IProperty[];

  allPropertiesIds: number[] = [];

  allPropertiesIdsCache: number[] = [];

  selectedProperty: number;

  isPartnerMaster = false;

  maxNumberOfSelectableProperties = 200;

  private featurePermission: string;

  private requiredModule: Module;

  private subs = new SubSink();

  private propertiesToEmit: number | number[];
  private lastEmittedProperties: number | number[];
  private originalSelectedProperties: number[];

  @ViewChild(NzSelectComponent) selectComponent: NzSelectComponent;

  @HostBinding('style.display')
  display = this.isVisible ? 'block' : 'none';

  constructor(
    private _store: Store<ICoreState>,
    private _router: Router,
    private selectService: PropertiesSelectService,
  ) {}

  get isVisible() {
    return (
      this.allPropertiesIdsCache?.length > 1 || this.showWithSingleProperty
    );
  }

  ngOnInit() {
    this.onSelectProperties();

    this.subs.add(
      this._router.events.subscribe((_event) => {
        if (_event instanceof NavigationEnd) {
          const properties =
            this.type === 'multiple'
              ? this.allPropertiesIds
              : [this.selectedProperty];
          this.selectService.selectedProperties.next(properties);
          this.selectService._selectedProperties = properties;
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showWithSingleProperty']) {
      this.setHostDisplayStyle();
    }

    const { type } = changes;

    if (type && type.previousValue !== type.currentValue) {
      const properties =
        this.type === 'multiple'
          ? this.allPropertiesIds
          : [this.selectedProperty];
      this.selectService.selectedProperties.next(properties);
      this.selectService._selectedProperties = properties;
    }
  }

  onSelectProperties() {
    this.subs.add(
      this._store
        .pipe(
          select(selectSelectedProperty),
          combineLatestOperator(
            this._store.pipe(select(selectAllProperties)),
            this._store.pipe(
              select(
                UserMePermissionsStoreSelectors.selectUserMePermissionsFeatureProperties(
                  this.featurePermission,
                ),
              ),
            ),
            this._store.pipe(
              select(ActiveModulesStoreSelectors.selectActiveModulesData),
            ),
          ),
        )
        .subscribe(
          ([
            property,
            selectGroupProperties,
            propertiesWithPermission,
            activeModules,
          ]) => {
            if (property) {
              this.selectedProperty = property.id;

              propertiesWithPermission = propertiesWithPermission.filter(
                (propertyId) =>
                  selectGroupProperties.findIndex(
                    ({ id }) => id === propertyId,
                  ) !== -1,
              );

              const propertyIsInPermission =
                propertiesWithPermission.indexOf(this.selectedProperty) !== -1;

              if (!propertyIsInPermission) {
                this.selectedProperty = propertiesWithPermission[0];
              }

              if (this.requiredModule) {
                const foundModule = activeModules?.[this.requiredModule];

                const { properties } = foundModule;

                const status = get(
                  properties,
                  `[${this.selectedProperty}].status`,
                  false,
                );

                if (!status) {
                  this.selectedProperty = +Object.keys(properties)
                    .filter(
                      (propertyId) =>
                        selectGroupProperties.findIndex(
                          ({ id }) => +id === +propertyId,
                        ) !== -1,
                    )
                    .find((propertyId) => {
                      return get(
                        foundModule.properties,
                        `[${propertyId}].status`,
                        false,
                      );
                    });
                }
              }

              if (this.type === 'default') {
                this.propertiesSelected.emit(this.selectedProperty);
                this.selectService.selectedProperties.next([
                  this.selectedProperty,
                ]);
                this.selectService._selectedProperties = [
                  this.selectedProperty,
                ];
              }
            }
          },
        ),
    );

    this.subs.add(
      combineLatest([
        this._store.pipe(select(selectAllProperties)),
        this._store.pipe(
          select(
            UserMePermissionsStoreSelectors.selectUserMePermissionsFeatureProperties(
              this.featurePermission,
            ),
          ),
        ),
        this._store.pipe(
          select(ActiveModulesStoreSelectors.selectActiveModulesData),
        ),
        this._store.pipe(select(UserMeStoreSelectors.selectData)),
        this._store.pipe(select(selectSelectedProperty)),
      ]).subscribe(
        ([
          properties,
          propertiesWithPermission,
          activeModules,
          userMeData,
          property,
        ]) => {
          this.isPartnerMaster = !!get(userMeData, 'partner_master');

          if (this.isPartnerMaster) {
            this.maxNumberOfSelectableProperties = 10;
          }

          const isAdminUser = findUserRole(userMeData, 'admin');

          this.properties = properties.filter((p) => {
            return (
              (!isAdminUser ? propertiesWithPermission : []).findIndex(
                (id) => +id === p.id,
              ) !== -1
            );
          });

          if (this.requiredModule) {
            const foundModule = activeModules?.[this.requiredModule];

            this.properties = this.properties.filter(({ id }) => {
              const foundStatus = get(
                foundModule?.properties,
                `[${id}].status`,
                false,
              );
              return foundStatus;
            });
          }

          this.allPropertiesIdsCache = this.properties.map(({ id }) => id);

          this.setHostDisplayStyle();

          const propertiesFromStorage =
            localStorage.getItem('allPropertiesIds');
          let storageProperties: number[];

          if (propertiesFromStorage) {
            storageProperties = propertiesFromStorage.split(',').map(Number);
          }

          if (this.type === 'multiple' && !this.isPartnerMaster) {
            if (storageProperties) {
              if (this.remember === false) {
                this.setAllPropertiesIds(storageProperties);
              } else if (this.remember === true) {
                if (this.skipLocalStorageProperties) {
                  clearLocalStorageProperties();
                  this.setAllPropertiesIds(this.properties.map(({ id }) => id));
                } else {
                  this.setAllPropertiesIds(storageProperties);
                }
              }
            } else {
              this.setAllPropertiesIds(this.properties.map(({ id }) => id));
            }
          }

          if (this.type === 'multiple' && this.isPartnerMaster) {
            const partnerApiPropertiesFromStorage = (
              localStorage.getItem(PARTNER_API_STORAGE_KEY) || ''
            )
              .split(',')
              .map(Number);

            const partnerApiProperties = intersection(
              partnerApiPropertiesFromStorage,
              this.properties.map(({ id }) => id),
            );

            if (partnerApiProperties.length) {
              this.setAllPropertiesIds(partnerApiProperties);
            } else {
              this.setAllPropertiesIds(property ? [property.id] : []);
            }
          }

          if (this.allPropertiesIds.length) {
            this.propertiesSelected.emit(this.allPropertiesIds);
            this.selectService.selectedProperties.next(this.allPropertiesIds);
            this.selectService._selectedProperties = this.allPropertiesIds;
          }
        },
      ),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setHostDisplayStyle() {
    this.display = this.isVisible ? 'block' : 'none';
  }

  onChangeSelectAll(propertiesIds) {
    if (propertiesIds === null || propertiesIds.length === 0) {
      this.setAllPropertiesIds([]);
      localStorage.setItem(
        'allPropertiesIds',
        this.allPropertiesIds.toString(),
      );
      this.storePartnerApiProperties(this.allPropertiesIds);
      return;
    }

    const all = propertiesIds.filter((value: string) => value === 'all');

    if (!all.length) {
      this.setAllPropertiesIds(propertiesIds);
      if (propertiesIds.length) {
        this.propertiesToEmit = propertiesIds;
      }
      localStorage.setItem(
        'allPropertiesIds',
        this.allPropertiesIds.toString(),
      );
      this.storePartnerApiProperties(this.allPropertiesIds);

      return;
    }

    if (propertiesIds.length === this.allPropertiesIdsCache.length + 1) {
      this.setAllPropertiesIds([]);
    } else {
      this.setAllPropertiesIds(this.allPropertiesIdsCache);
    }

    localStorage.setItem('allPropertiesIds', this.allPropertiesIds.toString());
    this.storePartnerApiProperties(this.allPropertiesIds);

    if (this.allPropertiesIds.length) {
      this.propertiesToEmit = this.allPropertiesIds;
    }
  }

  onChange(propertyId: number) {
    if (propertyId === null) {
      return;
    }
    this._store.dispatch(new SelectProperty({ propertyId }));
    this.propertiesToEmit = propertyId;
  }

  onOpenSelect() {
    if (this.type === 'multiple') {
      this.originalSelectedProperties = this.allPropertiesIds;
    }
  }

  onCloseSelect() {
    if (!this.allPropertiesIds.length && this.type === 'multiple') {
      this.allPropertiesIds = this.originalSelectedProperties;
      return;
    }

    if (
      isArray(this.propertiesToEmit) &&
      isArray(this.lastEmittedProperties) &&
      propertiesIsEqual(this.lastEmittedProperties, this.propertiesToEmit)
    ) {
      return;
    }

    if (
      this.propertiesToEmit &&
      this.propertiesToEmit === this.lastEmittedProperties
    ) {
      return;
    }

    if (
      !this.propertiesToEmit ||
      (isArray(this.propertiesToEmit) && !this.propertiesToEmit.length)
    ) {
      return;
    }

    this.lastEmittedProperties = this.propertiesToEmit;

    let propertiesForService = this.propertiesToEmit as number[];

    if (!isArray(this.propertiesToEmit)) {
      propertiesForService = [this.propertiesToEmit];
    }

    this.propertiesSelected.emit(this.propertiesToEmit);
    this.selectService.selectedProperties.next(propertiesForService);
    this.selectService._selectedProperties = propertiesForService;
  }

  get selectedProperties() {
    return intersectionBy(
      this.properties,
      this.allPropertiesIds.map((id) => ({
        id,
      })),
      'id',
    );
  }

  private setAllPropertiesIds(propertiesIds: number[]) {
    this.allPropertiesIds = [...propertiesIds].splice(
      0,
      this.maxNumberOfSelectableProperties,
    );
  }

  private storePartnerApiProperties(propertiesIds: number[]) {
    if (!this.isPartnerMaster) {
      return;
    }

    localStorage.setItem(
      PARTNER_API_STORAGE_KEY,
      (propertiesIds || []).toString(),
    );
  }
}
