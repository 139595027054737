<ng-container *ngIf="isVisible">
  <nz-select
    #selectComponent
    [nzDisabled]="onlyView"
    nzShowSearch
    [ngModel]="type === 'multiple' ? allPropertiesIds : selectedProperty"
    (ngModelChange)="
      type === 'multiple' ? onChangeSelectAll($event) : onChange($event)
    "
    id="{{ type === 'multiple' ? 'filterSelect' : 'filterSelectSingle' }}"
    [nzMode]="type"
    [nzMaxMultipleCount]="maxNumberOfSelectableProperties"
    [nzMaxTagPlaceholder]="selectedCount"
    [nzDropdownMatchSelectWidth]="false"
    [nzMaxTagCount]="0"
    nzDropdownClassName="select-custom-scrollbar"
    [ngStyle]="{ width: width, minWidth: minWidth }"
    class="beddy-multi-select"
    (nzOpenChange)="$event ? onOpenSelect() : onCloseSelect()"
    [nzDropdownRender]="type === 'multiple' && footer"
    [nzShowArrow]="true"
  >
    <nz-option
      *ngIf="type === 'multiple' && !isDisabled"
      nzCustomContent
      nzValue="all"
      ><span class="filterSelectAllSpan">{{
        'select_deselect_all' | translate
      }}</span></nz-option
    >
    <nz-option
      [nzDisabled]="isDisabled"
      *ngFor="let property of isDisabled ? selectedProperties : properties"
      [nzValue]="property.id"
      [nzLabel]="property.name"
    ></nz-option>
  </nz-select>
  <ng-template #footer>
    <nz-divider style="margin: 4px 0"></nz-divider>
    <div class="container-button-multiselect">
      <button
        nz-button
        (click)="selectComponent.setOpenState(false)"
        nzType="primary"
      >
        {{ 'ok' | translate | capitalize }}
      </button>
    </div>
  </ng-template>
</ng-container>

<ng-template #selectedCount>
  {{
    allPropertiesIds?.length === allPropertiesIdsCache?.length && !isDisabled
      ? ('all_properties_selected' | translate | capitalize)
      : ('properties_selected' | translate | capitalize) +
        ' ' +
        allPropertiesIds?.length
  }}
</ng-template>
